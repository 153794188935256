<template>
	<div class="animated fadeIn">
		<b-alert
			v-if="admin.includes($user.details().type)"
			show
			variant="secondary">
			<div class="row">
				<div class="col">
					<search
						ref="searchComp"
						v-model="distributor"
						:label-text="translate('autocomplete_label')"
						:endpoint="endpoint"
						:empty-result-message="translate('data_not_found')"
						:placeholder="'autocomplete_placeholder'"
						:default-params="{role:'distributor,pre_distributor'}"
						:init-value="String(distributor)"
						return-key="id"
						class="mb-3"
						q="label" />
					<div class="row">
						<div class="col">
							<b-button
								:disabled="loading"
								variant="primary"
								class="btn mx-1"
								@click="changeDistributor()">
								{{ translate('search') }}
							</b-button>
							<b-button
								:disabled="loading"
								variant="secondary"
								class="btn mx-1"
								@click="clearDistributor()">
								{{ translate('clear') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</b-alert>
		<div
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.fromId"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor'}"
							:init-value="filters.fromId"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							{{ translate('user_id') }}
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							{{ translate('username') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('from_id')">
							{{ translate('from_user_id') }}
							<sort field="from_id" />
						</th>
						<th
							class="border-top-0">
							{{ translate('from_username') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('order_id')">
							{{ translate('order_id') }}
							<sort field="order_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('current_bvs')">
							{{ translate('bv') }}
							<sort field="amount_right" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('next_process_date')">
							{{ translate('next_process_date') }}
							<sort field="created_at" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('last_date_processed')">
							{{ translate('processed_date') }}
							<sort field="created_at" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							<span
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							<template v-if="item.attributes.is_spillover">
								{{ translate('NA') }}
							</template>
							<span
								v-else-if="admin.includes($user.details().type) && item.attributes.from_user_type === distributorRole"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.from_id.toString() })">
								{{ item.attributes.from_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.from_id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.is_spillover ? `(${translate('spillover').toUpperCase()})` : item.attributes.from_username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.order_id }}
						</td>
						<td class="align-middle">
							{{ item.attributes.current_bvs }}/{{ item.attributes.total_bvs }}
						</td>
						<td class="align-middle">
							{{ item.attributes.next_process_date ? $moment(item.attributes.next_process_date.date).format(dateFormat) : null }}
						</td>
						<td class="align-middle">
							{{ item.attributes.last_date_processed ? $moment(item.attributes.last_date_processed.date).format(dateFormat) : null }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Report as BR, Grids, BinaryVolume, Countries, Users,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { YMD_FORMAT } from '@/settings/Dates';
import Bv from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import { admin, distributor } from '@/settings/Roles';
import Sponsored from '@/views/Network/mixins/Sponsored';
import DataFilter from '@/components/DataFilter/index.vue';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'Bv',
	messages: [BR, Grids, BinaryVolume, Grids, Countries, Users],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, Sponsored, DashboardRedirect],
	data() {
		return {
			bv: new Bv(),
			dateFormat: YMD_FORMAT,
			endpoint,
			admin,
			distributorRole: distributor,
		};
	},
	computed: {
		loading() {
			try {
				return this.bv.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.bv.data.errors.errors;
		},
		pagination() {
			return this.bv.data.pagination;
		},
		data() {
			try {
				const { data } = this.bv.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			try {
				return !!this.data.length;
			} catch (error) {
				return false;
			}
		},
		userStatus() {
			try {
				const { status } = this.bv.data.response.data.meta;
				return status;
			} catch (error) {
				return '';
			}
		},
	},
	mounted() {
		let userId = this.$user.details().id;
		if (this.$route.params.distributorId) {
			userId = this.$route.params.distributorId;
		}
		this.bv.getPendingBv(userId);
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			let userId = this.$user.details().id;
			if (this.$route.params.distributorId) {
				userId = this.$route.params.distributorId;
			}
			this.bv.getPendingBv(userId, options);
		},
	},
};
</script>
